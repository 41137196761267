import { createRouter, createWebHistory } from 'vue-router'
import { getCurrentUser } from '@/helpers/auth'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth',
    name: 'login',
    component: () => import('@/views/auth/LoginView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const user = await getCurrentUser()
  if (to.meta.requiresAuth && !user) {
    next('login');
  } else {
    next();
  }
})

export default router
